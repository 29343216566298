import ORMModel from '@/core/bridge/orm/ORMModel';
import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';

export default class UserV1HasRoleCoreModel extends ORMModelExtended {
  public static entity = 'users-v1-has-roles';

  public static primaryKey = ['user_id', 'role_id'];

  public static fields() {
    return {
      // tslint:disable-next-line
      user_id: this.number(null),
      // tslint:disable-next-line
      role_id: this.number(null),
    };
  }

  /**
   * Public fields
   */
  // tslint:disable-next-line
  public user_id!: number;
  // tslint:disable-next-line
  public role_id!: string;
}
